.App {
  text-align: center;
}

#root {
  /* background-image: radial-gradient(circle, red,  hotpink); */
  transition: background 1s ease-out; 
}

#root.step1 {
  background-position:1000px;
  /* background-image: radial-gradient(circle, SlateBlue, DodgerBlue); */
}
#root.step2 {
  background-position:500px;
  /* background-image: radial-gradient(circle, SlateBlue, DodgerBlue); */
}
#root.step3 {
  background-position:100px;
  /* background-image: radial-gradient(circle, SlateBlue, DodgerBlue); */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.dynamic-backdrop {
  height: 100%;
  width: 100%;
  z-index: 0;
  position: absolute;

}
.home-body {
  width: 100%;
  position: relative;
}
.vertical-middle {
  display: flex;
  justify-content: center;
}
.home-body .main-card {
  align-self: center;
}
.main-card {
  display: block;
  padding: 20px;
  width: 334px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  text-decoration: none;
  background: white;
  overflow: hidden;
}
.main-card-active-bar {
  display: block;
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  background: #ccc;
}
.flip-card-1:hover .main-card-active-bar,
.card-active.flip-card-1 .main-card-active-bar {
  background: rgb(117, 117, 117);
}
.flip-card-2:hover .main-card-active-bar,
.card-active.flip-card-2 .main-card-active-bar {
  background: #5a5a5a;
}
.flip-card-3:hover .main-card-active-bar,
.card-active.flip-card-3 .main-card-active-bar {
  background: rgb(189, 189, 189);
}
.flip-card-1 .main-card:focus {
  box-shadow: 0px 7px 17px rgb(0, 0, 0, .4);
}
.flip-card-2 .main-card:focus {
  box-shadow: 0px 7px 17px rgb(0, 0, 0, .4);
}
.flip-card-3 .main-card:focus {
  box-shadow: 0px 7px 17px rgb(0, 0, 0, .4);
}
.flip-card-0 .main-card-content {
  flex-direction: row;
}
.about-profile-main {
  width: 180px;
  padding-right: 20px;
  padding-top: 5px;
}
.about-profile-secondary {
  display: flex;
  flex-direction: row;
  position: relative;
}
.about-profile-secondary.col {
  flex-direction: column;
}
.about-profile-secondary.col .about-list {
  margin-top: 5px;
}
.about-profile-secondary.col .about-list li{
  display: inline-block;
  padding-right: 2px;
  margin-right: 3px;
}
.flip-card-0 .main-card-content .about-profile-secondary.col h1 {
  width: 100%;
  letter-spacing: 10px;
  line-height: .8em;
}
.flip-card-0 .main-card-content h1 {
  font-size: 3.0em;
  overflow-wrap: break-word;
  width: 82px;
  line-height: .6em;
  margin-top: 0px;
  padding-right: 10px;
  width: 74px;
  letter-spacing: 0px;
  transition: letter-spacing .5s, line-height .5s;
}
.flip-card-0 .main-card-content .h2 {
  font-size: 1.5em;
  margin-right: 4px;
  font-weight: 300;
  line-height: .8em;
  word-break: break-all;
}

.about-profile-pic {
  width: 70px;
  height: 70px;
  margin: 4px auto;
  border-radius: 50%;
  box-shadow: 0px 4px 4px  rgba(0, 0, 0, .3);
}

.main-card:hover {
  box-shadow: 0px 7px 17px  rgba(0, 0, 0, .3);
  text-decoration: none;
}

.main-card:focus {
  box-shadow: 0px 7px 17px  rgba(0, 0, 0, .3);
}


:focus {
  outline: 1px dotted #000;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
  box-shadow: 0 0 0 transparent;
}

.about-list {
  list-style-type: none;
  padding: 0;
  margin-left: 2px;
  margin-bottom: 0;
}

.about-ux {

}

.main-card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.child-card-content {
  
}

.card-detail {
  opacity: 0;
}

.card-active .card-detail {
  /* opacity: 1; */
}

.card-active .card-detail {
  -webkit-animation: .5s ease 0s normal forwards 1 fadein;
  animation: .5s ease 0s normal forwards 1 fadein;
}

@keyframes fadein{
  0% { opacity:0; }
  66% { opacity:0; }
  100% { opacity:1; }
}

@-webkit-keyframes fadein{
  0% { opacity:0; }
  66% { opacity:0; }
  100% { opacity:1; }
}

.flip-card {
  position: absolute;
  cursor: pointer;
  padding: 10px 0;
  box-sizing: border-box;
  /* box-shadow: 2px 5px 5px rgba(0, 0, 0, .3); */
    /* will-change: transform, opacity; */
}
h1, h2, h3,  .h1, .h2, .h3, h4, .h4{
  font-weight: 200;
}

.main-card h1 {
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-weight: 100; */
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  line-height: 24px;
  /* color: #333; */
}
.main-card h2, .main-card .h2 {
  margin-top: 13px;
  line-height: 18px;
  font-size: 1.1em;
}
.main-card h3 {
  padding: 0 20px 0 0;
  font-size: 1.3em;
  font-weight: 300;
}
.main-card h4 {
  position: absolute;
  bottom: 0;
  left: 20px;
  width: calc(100% - 40px);
  text-overflow: ellipsis;
  padding: 0;
  text-transform: lowercase;
}
.detail-link {
  text-decoration: none;
  display: inline-block;
  padding: 4px;
  color: #fff;
  background-color:#505768;
  border: 1px solid #505768
}
.card-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}
.card-text ul {
  list-style-type: square;
  padding-left: 16px;
}
.card-text li {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
}
.card-arrow-svg {
  transition: transform;
}
.card-arrow-svg-open {
  transform: rotate(45deg);
  stroke: #cccccc
}

.card-arrow-svg-closed {
  transform: rotate(0deg);
  stroke: #333333
}
.card-arrow-svg-open .card-arrow-mark {
  stroke: #777777
}
.card-arrow-svg-closed .card-arrow-mark {
  stroke: #333333
}
.card-arrow {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 25px;

}
.card-arrow .svg-shape {
  stroke: rgba(0,0,0,.3)
}


/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} 
*/